import { getProdList, updateAppName } from 'api/product/overall';
import { selectAccountRole } from 'api/account/permission';
import { selectProds, selectApps } from 'api/product/overall';
import Pagination from '@/components/pagination';
import { filterObject } from 'utils/utils';
import qs from 'querystring';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filter: {
        prod: null,
      },
      loading: {
        list: false,
        submitBtn: false,
      },
      total: null,
      pageSize: 10,
      currentPage: 1,
      prodList: [],
      appList: [],
      pkgList: [],
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      productObj: {
        id: 0,
        name: '',
        productManager: '',
        productManagerMaps: [
          {
            pm: '',
            type: '',
            appType: '',
          },
        ],
      },
      option: {
        manager: [],
        pmTypes: [
          { label: 'overseas', value: 'overseas' },
          {
            label: 'domestic',
            value: 'domestic',
          },
        ],
        importants: [
          {
            label: 'important',
            value: 'important',
          },
          {
            label: 'secondary',
            value: 'secondary',
          },
        ],
        offerType: [
          { label: 'ua', value: 'ua' },
          { label: 'rt', value: 'rt' },
        ],
      },
      rules: {
        name: [{ required: true, message: 'app name不能为空' }],
        productManager: [{ required: true, message: 'productManager不能为空' }],
      },
    };
  },
  mounted() {
    this.searchClick(1);
    this.managerList();
    this.selectAppsList();
    // this.prodsList();
    this.selectAccountRoles();
    this.selectUserName();
  },
  computed: {
    ...mapState('user', {
      accountRoles: (state) => state.roles,
      userName: (state) => state.userName,
    }),
  },
  methods: {
    // get roles
    ...mapActions('user', ['selectAccountRoles', 'selectUserName']),
    searchClick(curPage) {
      if (curPage) {
        this.currentPage = curPage;
      }
      const param = {
        ...this.filter,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.loading.list = true;
      getProdList(param)
        .then((response) => {
          if (response.code === 200) {
            this.prodList = response.result;
            this.total = response.total;
          } else {
            this.$message.error('Error:' + response.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.loading.list = false;
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.searchClick();
    },
    prodDetail(row) {
      this.$router.push({
        path: '/product/detail',
        query: {
          prodId: row.id,
          packageName: row.prod,
          affBlacklistSwitch: row.affBlacklistSwitch,
        },
      });
    },
    selectAppsList() {
      selectApps().then((res) => {
        this.appList = res.result;
      });
    },
    // 获取prods
    prodsList() {
      selectProds().then((res) => {
        this.pkgList = res.result;
      });
    },
    handleAppNameDialog(row) {
      this.productObj.id = row.id;
      this.productObj.name = row.name;
      if (row.productManager === '[]') {
        const dataFiltersObj = {
          pm: '',
          type: '',
          appType: '',
        };
        let list = [];
        list.push(dataFiltersObj);
        this.productObj.productManagerMaps = list;
      } else {
        this.productObj.productManagerMaps = row.productManagerMaps;
      }

      this.dialog.visible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          let obj = {};
          obj.id = this.productObj.id;
          obj.name = this.productObj.name;
          obj.productManager = this.productObj.productManager;
          obj.productManagerMaps = this.productObj.productManagerMaps;
          updateAppName(obj).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.searchClick();
              this.dialog.visible = false;
              this.loading.submitBtn = false;
            } else {
              this.$message.error('Update Error:' + response.message);
              this.loading.submitBtn = false;
            }
          });
        } else {
          this.$message.error('必填参数缺少');
          return false;
        }
      });
    },
    // 获取manager列表
    managerList() {
      selectAccountRole({ roleName: 'Ad Operation' }).then((res) => {
        console.log(res);
        this.option.manager = res.result;
      });
    },
    // 添加 data Filter item
    dataFilterAdd() {
      const dataFiltersObj = {
        pm: '',
        type: '',
        appType: '',
      };
      this.productObj.productManagerMaps.push(dataFiltersObj);
    },
    // 删除 data Filter item
    dataFilterDel(index) {
      this.productObj.productManagerMaps.splice(index, 1);
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载5万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filter };
          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/prod/export?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error.message,
          });
        });
    },
  },
};
